import * as React from "react";
import Contact from "../components/contact";
import HeroHome from "../components/hero-home";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import Slider from "../components/slider";
import Tours from "../components/tours";

const IndexPage = () => {
  return (
    <Layout>
      <HeroHome />
      <Slider />
      <div className="space-y-4">
        <Contact />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
