import * as React from "react";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import logo from "../../images/logo.png";

export default function Slider() {
  const data = useStaticQuery(
    graphql`
      query {
        hero1: file(relativePath: { eq: "bg/hero1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero2: file(relativePath: { eq: "bg/hero2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero3: file(relativePath: { eq: "bg/hero3.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const [count, setCount] = React.useState(1);
  const [imgData, setImageData] = React.useState(
    data.hero1.childImageSharp.fluid
  );

  const keyLength = Object.keys(data).length;
  console.log(keyLength);

  React.useEffect(() => {
    setTimeout(() => {
      if (count === keyLength - 1) {
        setCount(0);
      } else {
        setCount(count + 1);
      }
      console.log(count);
      setImageData(data[Object.keys(data)[count]].childImageSharp.fluid);
    }, 3000);
  }, [count]);

  // Set ImageData.

  return (
    <BackgroundImage
      Tag="section"
      className={"block h-80 w-full"}
      style={{ minHeight: 780, minWidth: "100%" }}
      fluid={imgData}
      backgroundColor={`#040e18`}
    >
      <img
        src={logo}
        className="flex w-32 mb-auto"
        alt="Logo yourmomentfactory"
      />
    </BackgroundImage>
  );
}
