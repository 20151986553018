import { faCoffee, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function HeroHome() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <StaticImage
            src="../../images/logo.png"
            className="w-40 h-40 mt-4 mb-4"
            alt="Logo"
          />
          <h2 className="text-3xl font-bold text-black mb-4">SwissFranx</h2>
          <p className="mt-4 text-lg text-gray-500">
            Was ist SwissFranx? SwissFranx ist ein Lifestyle den wir kreiert
            haben für all die, die das Leben und die Musik geniessen. Unser
            Hauptstandort ist in der Schweiz, jedoch sind wir auf der ganzen
            Welt vernetzt, was unseren Artisten und Partnern ein breites
            Netzwerk bietet.
          </p>

          <p className="mt-4 text-lg text-gray-500">
            Fühlst du dich als einer von uns, dann zeig es! Lebe dein bestes
            Leben!
            <br /> SwissFranx verurteilt keiner, wir erschaffen! <br />
            SwissFranx verliert nicht, wir gewinnen! Wilkommen zur SwissFranx
            Familie!
          </p>
        </div>
      </div>
    </div>
  );
}
